<template>
  <v-app>
    <div class="page-wrap text-center">
      <div class="session-form-hold">
        <v-card  class="rounded-lg">
          <v-card-text>
            <div
              style="
                display: flex;
                justify-content: center;
                padding-bottom: 20px;
              "
            >
              <div>
                <v-img
                  width="270"
                  height="80"
                  contain
                  src="https://firebasestorage.googleapis.com/v0/b/whitelabel-156bb.appspot.com/o/logoLondrinaDark.png?alt=media&token=7672cda6-7108-4336-bdb6-8c3ed3c83d87"
                  alt=""
                />
              </div>
            </div>
            <!-- <v-icon>mdi-plus</v-icon> -->
            <!-- <div class="d-flex align-center justify-center mb-6 mt-3">
            <small class="mr-2 lh-1">PoweredBy</small>
            <img
              v-if="$vuetify.theme.dark"
              height="14"
              src="/img/logo/voy-dark.png"
              alt=""
            />
            <img
              v-else
              height="14"
              src="/img/logo/voy-light.png"
              alt=""
            />
          </div> -->
            <RegisterForm />
          </v-card-text>
        </v-card>
        <v-btn class="mt-4" dark small text href="https://lec.agroplayingressos.com/" target="_blank">
          <v-icon left small>mdi-arrow-left</v-icon>
          Voltar para o site
        </v-btn>
      </div>
    </div>
  </v-app>
</template>
<script>
import RegisterForm from "@/components/RegisterForm.vue";

export default {
  components: { RegisterForm },
  name: "App",
  metaInfo: {
    title: "Acessar conta",
  },
  data() {
    return {
      loading: false,
      pageLoading: true,
      error: false,
    };
  },
  methods: {
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.changeThemeDarkMode(this.$vuetify.theme.dark);
    },
   
  },
  computed: {},
  mounted() {},
};
</script>
<style lang="scss">
.page-wrap {
  background: radial-gradient(
      circle at 50% 50%,
      rgba(0, 0, 0, 50%) 0%,
      rgba(0, 0, 0, 90%) 90vw
    ),
    url(https://firebasestorage.googleapis.com/v0/b/whitelabel-156bb.appspot.com/o/back-noticias.png?alt=media&token=67ba87f5-a3d8-49f5-a1bc-c1f6cb9951ed),
    black;
  display: flex;
  background-size: cover;
  background-position: center;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}

.session-form-hold {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.v-stepper__label {
  display: block;
  flex-grow: 1;
  line-height: 1;
  text-wrap: nowrap !important;
}
</style>
