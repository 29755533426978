import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://hook.us1.make.com/nheggvuocujs75ftn8x9jzm1utb48qps',
  headers: {
    'Content-Type': 'application/json'
  }
});

export default {
  postUser(user) {
    return apiClient.post('', user);
  }
};