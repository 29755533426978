<template>
  <div class="text-left">
    <strong>Local e Horário:</strong>
    <ul class="mb-4">
      <li><strong>Data:</strong> {{ env.VUE_APP_GAME_DATE }}</li>
      <li><strong>Horário:</strong> {{ env.VUE_APP_GAME_TIME }}</li>
      <li><strong>Local:</strong> {{ env.VUE_APP_GAME_STADIUM }}</li>
    </ul>

    <strong>Regras para menores de idade:</strong>
    Primeiramente, todos os menores de {{env.VUE_APP_MAX_AGE}} anos devem estar acompanhados por um
    responsável legal ou adulto autorizado durante toda a sua estadia no
    estádio.
  </div>
</template>

<script>
export default {
  name: "InformativeGame",
  computed: {
    env() {
      return process.env;
    },
  },
};
</script>

<style scoped></style>
