<template>
  <div>
    <v-stepper elevation="0" v-model="e1" alt-labels>
      <v-stepper-header style="box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0)">
        <v-stepper-step :complete="e1 > 1" step="1">Informações</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 2" step="2">Responsável</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 3" step="3">Menor</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content class="pt-0" step="1">
          <div class="d-block d-md-none pb-4">
            <span class="font-weight-bold">Informações</span>
          </div>
          <v-card outlined elevation="0" class="pa-3 mb-4">
            <div class="d-flex justify-space-around">
              <div class="d-flex flex-column justify-center">
                <img
                  height="80"
                  contain
                  src="../assets/image/time2.png"
                  alt=""
                />
                <span style="text-align: center; font-weight: bold"
                  >Londrina</span
                >
              </div>
              <div class="d-flex flex-column justify-center">
                <span
                  class="text-16"
                  style="align-content: center; font-weight: bold"
                  >VS</span
                >
                <small class="text--secondary" style="line-height: 1rem">{{
                  env.VUE_APP_GAME_DATE
                }}</small>
                <small class="text--secondary" style="line-height: 1rem"
                  >{{ env.VUE_APP_GAME_TIME }} horas</small
                >
              </div>
              <div class="d-flex flex-column justify-center">
                <img height="80" contain :src="env.VUE_APP_TEAM_LOGO" alt="" />
                <span style="text-align: center; font-weight: bold">{{
                  env.VUE_APP_TEAM_NAME
                }}</span>
              </div>
            </div>
          </v-card>
          <informative-game />
          <v-checkbox
            label="Aceito os termos e condições"
            v-model="termsAccepted"
          ></v-checkbox>
          <v-btn :disabled="!termsAccepted" color="primary" @click="e1 = 2">
            Continuar
          </v-btn>
        </v-stepper-content>

        <v-stepper-content class="pt-0" step="2">
          <div class="d-block d-md-none pb-4">
            <span class="font-weight-bold">Dados do responsável</span>
          </div>
          <v-form v-model="validResponsible" class="mt-2">
            <v-text-field
              dense
              label="Nome"
              outlined
              v-model="user.name"
              :rules="[
                (v) => !!v || 'Nome é obrigatório',
                (v) =>
                  v.trim().split(' ').length > 1 ||
                  'Nome completo é obrigatório',
              ]"
            ></v-text-field>
            <v-text-field
              dense
              persistent-hint
              outlined
              label="CPF"
              v-model="user.cpf"
              :rules="cpfRules"
              @input="maskCpf"
              maxlength="14"
            ></v-text-field>
            <v-text-field
              dense
              label="Telefone"
              hint="O ingresso do menor será enviado para esse telefone"
              persistent-hint
              outlined
              :rules="phoneRules"
              v-model="user.phone"
              @input="maskPhone"
              maxlength="15"
            />
          </v-form>
          <div class="d-flex justify-space-between">
            <v-btn @click="e1 = 1" text>Voltar</v-btn>
            <v-btn
              color="primary"
              @click="e1 = 3"
              :disabled="!validResponsible"
            >
              Continuar
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content class="pt-0" step="3">
          <div class="d-block d-md-none pb-4">
            <span class="font-weight-bold">Dados do Menor</span>
          </div>
          <v-form v-model="valid" class="mt-2">
            <v-text-field
              dense
              label="Nome do Menor"
              outlined
              v-model="user.minorName"
              :rules="[
                (v) => !!v || 'Nome é obrigatório',
                (v) =>
                  v.trim().split(' ').length > 1 ||
                  'Nome completo é obrigatório',
              ]"
            ></v-text-field>
            <div class="pb-5">
              <v-text-field
                dense
                label="Data de Nascimento"
                type="date"
                outlined
                v-model="user.birthDate"
                hide-details
                :rules="[(v) => !!v || 'Data de nascimento é obrigatória']"
              ></v-text-field>
            </div>
            <v-select
              dense
              :items="relationshipItems"
              label="Parentesco"
              outlined
              v-model="user.relationship"
              :rules="[(v) => !!v || 'Parentesco é obrigatório']"
            ></v-select>
            <v-alert
              class="text-left"
              v-if="!ageValidate && this.user.birthDate"
              type="error"
              >Idade máxima para entrada gratuita é de
              {{ env.VUE_APP_MAX_AGE }} anos para maiores é necessário adquirir
              um ingresso.
            </v-alert>
          </v-form>
          <div class="d-flex justify-space-between">
            <v-btn @click="e1 = 2" text>Voltar</v-btn>
            <v-btn
              color="primary"
              @click="submitUser(), (e1 = 4)"
              :disabled="!valid || !ageValidate"
            >
              Finalizar
            </v-btn>
          </div>
        </v-stepper-content>
        <v-stepper-content class="pt-0" step="4">
          <div class="d-flex flex-column gap-4 pt-3">
            <v-icon color="success" x-large>mdi-check-circle</v-icon>
            <h5>Cadastro realizado</h5>
            <p class="mb-2">
              Os ingressos serão enviados para o número {{ user.phone }} no dia
              do jogo!
            </p>
            <div class="d-flex flex-wrap justify-space-between">
              <v-btn text @click="resetForm()">Cadastrar outro menor</v-btn>
              <v-btn color="info" href="https://lec.agroplayingressos.com/">
                Finalizar
              </v-btn>
            </div>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import validateCpf from "@/utils/validate-cpf";
import apiService from "@/services/apiService";
import InformativeGame from "@/components/InformativeGame.vue";
import moment from "moment";

export default {
  name: "RegisterForm",
  components: { InformativeGame },
  data() {
    return {
      e1: 1,
      termsAccepted: false,
      valid: false,
      validResponsible: false,
      relationshipItems: [
        "Pai/Mãe",
        "Avô/Avó",
        "Tio/Tia",
        "Padrinho/Madrinha",
        "Outro",
      ],
      user: {
        name: "",
        cpf: "",
        phone: "",
        minorName: "",
        birthDate: "",
        relationship: "",
      },
      cpfRules: [
        (v) => !!v || "CPF é obrigatório",
        (v) => validateCpf(v) || "CPF inválido",
      ],
      phoneRules: [
        (v) => !!v || "Telefone é obrigatório",
        (v) => v.length >= 14 || "Telefone inválido",
      ],
    };
  },
  methods: {
    submitUser() {
      if (this.canValidateStep2) {
        apiService
          .postUser(this.user)
          .then((response) => {
            console.log("Usuário registrado com sucesso!", response);
          })
          .catch((error) => {
            console.error("Erro ao registrar usuário:", error);
          });
      }
    },
    resetForm() {
      this.e1 = 3;
      this.termsAccepted = false;
      this.valid = false;
      this.user = {
        ...this.user,
        minorName: "",
        birthDate: "",
        relationship: "",
      };
    },
    maskCpf() {
      let numbers = this.user.cpf.replace(/\D/g, "");

      if (numbers.length > 11) {
        numbers = numbers.substring(0, 11);
      }

      this.user.cpf = numbers.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
        "$1.$2.$3-$4"
      );
    },
    maskPhone() {
      let numbers = this.user.phone.replace(/\D/g, "");

      if (numbers.length > 11) {
        numbers = numbers.substring(0, 11);
      }

      this.user.phone = numbers.replace(
        /^(\d{2})(\d{4,5})(\d{4})$/,
        "($1) $2-$3"
      );
    },
  },
  computed: {
    env() {
      return process.env;
    },
    ageValidate() {
      if (!this.user.birthDate) return false;
      const today = moment();
      const birthDate = moment(this.user.birthDate);
      const age = today.diff(birthDate, "years");

      if (age <= this.env.VUE_APP_MAX_AGE) {
        return true;
      }

      return false;
    },
    canValidateStep2() {
      return (
        this.user.name &&
        this.user.cpf &&
        this.user.phone &&
        validateCpf(this.user.cpf) &&
        this.phoneRules.every((rule) => rule(this.user.phone) === true)
      );
    },
    canValidateStep3() {
      return (
        this.user.minorName &&
        this.user.birthDate &&
        this.user.relationship &&
        !this.ageValidate
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@for $i from 0 through 16 {
  .gap-x-#{$i} {
    column-gap: #{$i * 0.25}rem;
  }
  .gap-y-#{$i} {
    row-gap: #{$i * 0.25}rem;
  }

  .gap-#{$i} {
    gap: #{$i * 0.25}rem;
  }
}
</style>
